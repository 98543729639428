import { Controller } from "@hotwired/stimulus";
import AudioPlayerController from "controllers/audio_player_controller";
export default class extends AudioPlayerController {
  static targets = [
    "button",
    "courseTitle",
    "lessonTitle",
    "lessonPosition",
    "lessonDuration",
    "playerBackground",
    "courseLogo",
    "spinerLoading",
  ];
  connect() {
    this.buttonTargets.forEach((button, index) => {
      if (index !== 0) {
        // Make the remaining buttons inactive
        Array.from(button.classList).forEach((className) => {
          if (className.startsWith("text-") || className.startsWith("bg-")) {
            button.classList.remove(className);
          }
        });
        button.classList.add("bg-BrightStone", "text-DarkStone");
      } else {
        button.click();
      }
    });

    document.addEventListener('turbo:before-visit', this.pause.bind(this));
    document.addEventListener('beforeunload', this.pause.bind(this));
  }

  disconnect() {
    document.removeEventListener('turbo:before-visit', this.pause.bind(this));
    document.removeEventListener('beforeunload', this.pause.bind(this));
  }

  initialize() {
    this.index = 0;
  }
  selectLesson(event) {
    var audioUrl = event.currentTarget.getAttribute("attr-audio");
    var fgColor = event.currentTarget.getAttribute(
      "attr-course-fg_color-value"
    );
    var courseLessonId = event.currentTarget.getAttribute(
      "attr-course-id-value"
    );
    var lessonNameValue = event.currentTarget.getAttribute(
      "attr-lesson-name-value"
    );
    var courseNameValue = event.currentTarget.getAttribute(
      "attr-course-name-value"
    );
    var courseLogo = event.currentTarget.getAttribute("attr-course-logo-value");
    var lessonPosition = event.currentTarget.getAttribute(
      "attr-position-value"
    );
    var lessonDuration = event.currentTarget.getAttribute(
      "attr-duration-value"
    );
    var initialSeekValue = event.currentTarget.getAttribute("attr-seek-value");
    var playerColor = event.currentTarget.getAttribute(
      "attr-palyer-color-value"
    );

    var courseValue = this.courseTitleTarget;
    var lessionValue = this.lessonTitleTarget;
    var position = this.lessonPositionTarget;
    var duration = this.lessonDurationTarget;
    var logo = this.courseLogoTarget;

    position.innerHTML = lessonPosition;
    duration.innerHTML = lessonDuration;
    lessionValue.innerHTML = lessonNameValue;
    courseValue.innerHTML = courseNameValue;
    logo.setAttribute("src", courseLogo);

    // Add the new playerColor class
    for (let tgt of this.playerBackgroundTargets) {
      // Remove existing classes starting with "bg-" to remove background color
      Array.from(tgt.classList).forEach((className) => {
        if (className.startsWith("bg-")) {
          tgt.classList.remove(className);
        }
      });

      // Add the new background color class
      tgt.classList.add(playerColor);
    }

    for (let tgt of this.courseTitleTargets) {
      Array.from(tgt.classList).forEach((className) => {
        if (className.startsWith("text-")) {
          tgt.classList.remove(className);
        }
      });
      tgt.classList.add(fgColor);
    }

    for (let tgt of this.lessonTitleTargets) {
      Array.from(tgt.classList).forEach((className) => {
        if (className.startsWith("text-")) {
          tgt.classList.remove(className);
        }
      });
      tgt.classList.add(fgColor);
    }
    this.prepareAudio(
      audioUrl,
      initialSeekValue,
      courseNameValue,
      lessonNameValue
    );
    // Get the selected button element
    const selectedButton = event.currentTarget;

    for (let tgt of this.buttonTargets) {
      if (tgt === selectedButton) {
        tgt.classList.remove("bg-BrightStone", "text-DarkStone");
        tgt.classList.add(playerColor, fgColor);
      } else {
        Array.from(tgt.classList).forEach((className) => {
          if (className.startsWith("text-") || className.startsWith("bg-")) {
            tgt.classList.remove(className);
          }
        });
        tgt.classList.add("bg-BrightStone", "text-DarkStone");
      }
    }
  }
  prepareAudio(audioUrl, initialSeekValue, courseNameValue, lessonNameValue) {
    var controller = this;
    controller.progressTarget.style.backgroundSize = "";
    if (this.sound !== undefined) {
      this.sound.stop();
      controller.showPlay();
    }
    controller.completed = false;
    this.sound = new Howl({
      src: [audioUrl],
      html5: true,
      onpause: function () {
        controller.showPlay();
        clearInterval(controller.intervalId);
      },
      onstop: function () {
        clearInterval(controller.intervalId);
      },
      onend: function () {
        controller.showPlay();
        clearInterval(controller.intervalId);
        controller.closePlayer();
        controller.completed = true;
      },
      onplay: function () {
        controller.id = requestAnimationFrame(controller.step.bind(controller));
        controller.showPause();
      },
      onseek: debounce(function (event) {
        controller.id = requestAnimationFrame(controller.step.bind(controller));
        controller.seekValue = controller.sound.seek();
        initialSeekValue = controller.sound.seek();
      }, 1000),
    });
    function debounce(func, delay) {
      let timerId;
      return function (...args) {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    }
    this.sound.once("load", function () {
      if ("mediaSession" in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: lessonNameValue,
          album: courseNameValue,
        });
        navigator.mediaSession.setActionHandler("play", function () {
          controller.play();
        });
        navigator.mediaSession.setActionHandler("pause", function () {
          controller.pause();
        });
        navigator.mediaSession.setActionHandler("seekto", function (event) {
          controller.sound.seek(event.seekTime);
        });
      }
      for (let tgt of controller.durationTargets) {
        tgt.innerHTML = controller.formatTime(controller.sound.duration());
      }
      if (initialSeekValue) {
        for (let tgt of controller.progressTargets) {
          tgt.value = initialSeekValue;
        }
        controller.sound.seek(initialSeekValue);
      }
      var totalDuration = controller.sound.duration();
      for (let tgt of controller.progressTargets) {
        tgt.setAttribute("max", totalDuration);
      }
    });
    this.sound.seek(initialSeekValue);
    // this.sound.play();
  }

  seek() {
    cancelAnimationFrame(this.id);
    for (let tgt of this.progressTargets) {
      var seekedTime = event.currentTarget.value;

      this.sound.seek(seekedTime);
    }
  }

  play() {
    if (this.sound.playing()) {
      return;
    }
    this.spinerLoadingTarget.classList.remove("hidden");
    this.sound.play();
  }

  closePlayer(event) {
    this.progressTarget.style.backgroundSize = "";
  }

  step() {
    var seek = this.sound.seek() || 0;

    for (let tgt of this.progressTargets) {
      tgt.value = seek;
    }

    for (let tgt of this.seekTargets) {
      tgt.innerHTML = this.formatTime(seek);
    }
    // If sound is still playing, continue stepping
    if (this.sound.playing()) {
      this.spinerLoadingTarget.classList.add("hidden");
      var total_seconds = parseInt(this.sound.duration());
      var completed_seconds = parseInt(seek);
      var percent =
        total_seconds > 0
          ? Math.round((completed_seconds / total_seconds) * 100)
          : 0;
      var rengeSize = `${percent + 0.8}% 100%`;

      for (let tgt of this.progressTargets) {
        tgt.style.backgroundSize = rengeSize;
      }

      this.id = requestAnimationFrame(this.step.bind(this));
    }
  }

  nextLesson() {
    this.index = (this.index + 1) % this.buttonTargets.length;
    this.showSlide();
  }

  previousLesson() {
    this.index =
      (this.index - 1 + this.buttonTargets.length) % this.buttonTargets.length;
    this.showSlide();
  }

  showSlide() {
    this.buttonTargets.forEach((slide, i) => {
      if (i === this.index) {
        slide.click();
      }
    });
  }
}
