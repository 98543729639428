import { EventBus } from "../utilities/event_bus";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "course",
    "lesson",
    "lessonWrapper",
    "allLessons",
    "lessonEmpty",
    "maxCharacters",
    "lessonRemainder",
  ];

  connect() {
    this.showFilteredLessons();
  }

  showFilteredLessons() {
    if (this.courseTarget.value != "") {
      this.lessonWrapperTarget.classList.remove("hidden");
      this.lessonEmptyTarget.disabled = true;
      this.updateMaxCharacters();

      Array.from(this.lessonTarget.options).forEach((option) => {
        option.remove();
      });

      Array.from(this.allLessonsTarget.options).forEach((option) => {
        if (option.dataset.courseId == this.courseTarget.value) {
          var clone = option.cloneNode(true);
          this.lessonTarget.appendChild(clone);
        }
      });
    } else {
      this.lessonWrapperTarget.classList.add("hidden");
      this.lessonEmptyTarget.disabled = false;
      if (this.maxCharactersTarget) {
        this.maxCharactersTarget.innerHTML = 160;
      }
    }
    EventBus.emit("count-characters");
  }

  updateMaxCharacters() {
    if (this.maxCharactersTarget) {
      if (this.lessonRemainderTarget.checked) {
        this.maxCharactersTarget.innerHTML = 130;
      } else {
        this.maxCharactersTarget.innerHTML = 160;
      }
    }
  }
}
