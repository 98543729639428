// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus";
import RailsNestedForm from "@stimulus-components/rails-nested-form";
import { definitionsFromContext } from "@stimulus/webpack-helpers";

const application = Application.start();
application.register("nested-form", RailsNestedForm);
application.register("nested-form-tag", RailsNestedForm);
const context = require.context("controllers", true, /_controller\.js$/);
application.load(definitionsFromContext(context));

// Reloads page on Subscription page if it was cached (back forward cache) to prevent form not firing on Safari.

window.addEventListener("pageshow", function (event) {
  var historyTraversal =
    event.persisted ||
    (typeof window.performance != "undefined" &&
      window.performance.getEntriesByType("navigation")[0].type ===
        "back_forward");
  if (
    window.location.pathname.startsWith("/subscriptions/new") &&
    historyTraversal
  ) {
    // Handle page restore.
    window.location.reload();
  }
});
