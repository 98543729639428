import { Controller } from "@hotwired/stimulus";
import AudioPlayerController from "controllers/audio_player_controller";

// Connects to data-controller="lesson-audio-player"

export default class extends AudioPlayerController {
  static targets = [
    "audioPlayer",
    "audioPlayerCourse",
    "audioPlayerCourseIcon",
    "audioPlayerLesson",
    "audioPlayerSound",
    "audioPlayerMute",
    "maxPlayerContainer",
    "minPlayerContainer",
    "progressMaxPlayer",
    "seekMaxPlayer",
    "durationMaxPlayer",
    "audioPlayerVolume",
    "maxBasicDetails",
    "maxDetails",
    "maxTranscript",
    "completedPercentage",
    "showAlert",
    "courseLesson",
    "audioPlayerSsml",
    "widthRemove",
  ];

  connect() {
    window.addEventListener("resize", this.updateSliderCircleBg.bind(this));
    this.updateSliderCircleBg();
    this.resetSwipeBox();

    this.minPlayerContainerTarget.addEventListener(
      "touchstart",
      this.handleTouchStart.bind(this)
    );
    this.minPlayerContainerTarget.addEventListener(
      "touchmove",
      this.handleTouchMove.bind(this)
    );
    this.minPlayerContainerTarget.addEventListener(
      "touchend",
      this.handleTouchEnd.bind(this)
    );
  }

  initialize() {
    this.volumeData = 0.5;
    this.volumeMuted = false;

    for (let tgt of this.audioPlayerVolumeTargets) {
      tgt.value = this.volumeData;
    }
  }

  selectLesson() {
    this.resetSwipeBox();

    var audioUrl = event.currentTarget.getAttribute("attr-audio");
    var checkLocked = event.currentTarget.getAttribute("data-check-locked");
    var subscriptionList = event.currentTarget.getAttribute(
      "attr-subscription-list-url"
    );
    if (checkLocked === "true") {
      window.location.href = subscriptionList;
      return;
    }
    var courseLessonId = event.currentTarget.getAttribute(
      "attr-course-lesson-id-value"
    );
    var lessonNameValue = event.currentTarget.getAttribute(
      "attr-lesson-name-value"
    );
    var appNameValue = event.currentTarget.getAttribute("attr-app-name-value");
    var courseNameValue = event.currentTarget.getAttribute(
      "attr-course-name-value"
    );
    var courseIconValue = event.currentTarget.getAttribute(
      "attr-course-icon-value"
    );
    var coursePageLink = event.currentTarget.getAttribute(
      "attr-course-page-link-value"
    );
    var courseBgClass = event.currentTarget.getAttribute(
      "attr-course-bg-color-value"
    );
    var artworkUrlValue = event.currentTarget.getAttribute(
      "attr-artwork-url-value"
    );
    var ssmlValue = event.currentTarget.getAttribute("attr-ssml-value");
    var category_image = event.currentTarget.getAttribute(
      "attr-category-image-value"
    );

    this.maxPlayerContainerTarget.style.backgroundImage = `url(${category_image})`;
    try {
      if (this.idValue == courseLessonId && this.sound.playing()) {
        return;
      }
    } catch (err) {}

    var subscriptionAudioSeekURL = event.currentTarget.getAttribute(
      "attr-audio-seek-url-value"
    );
    var initialSeekValue = this.seekValue !== undefined ? this.seekValue : 0;

    this.fetchInitialSeekValue(subscriptionAudioSeekURL)
      .then((seekValue) => {
        initialSeekValue = seekValue;

        if (this.audioPlayerTarget.classList.contains("hidden")) {
          this.audioPlayerTarget.classList.remove("hidden");
          this.audioPlayerTarget.classList.add("playerBottom");
        }

        this.audioPlayerTarget.setAttribute(
          "attr-course-lesson-id-value",
          courseLessonId
        );
        this.audioPlayerTarget.setAttribute("attr-audio-url-value", audioUrl);
        this.audioPlayerTarget.setAttribute(
          "attr-lesson-name-value",
          lessonNameValue
        );
        this.audioPlayerTarget.setAttribute(
          "attr-app-name-value",
          appNameValue
        );
        this.audioPlayerTarget.setAttribute(
          "attr-course-name-value",
          courseNameValue
        );
        this.audioPlayerTarget.setAttribute(
          "attr-artwork-url-value",
          artworkUrlValue
        );
        this.audioPlayerTarget.setAttribute(
          "attr-initial-seek-value",
          initialSeekValue
        );
        this.audioPlayerTarget.setAttribute("attr-ssml-value", ssmlValue);

        for (let tgt of this.audioPlayerCourseTargets) {
          tgt.setAttribute("href", coursePageLink);
          tgt.innerHTML = courseNameValue;
        }

        for (let tgt of this.audioPlayerCourseIconTargets) {
          tgt.setAttribute("src", courseIconValue);

          // Remove existing classes starting with "bg-" to remove background color
          Array.from(tgt.classList).forEach((className) => {
            if (className.startsWith("bg-")) {
              tgt.classList.remove(className);
            }
          });

          // Add the new background color class
          tgt.classList.add(courseBgClass);
        }

        for (let tgt of this.audioPlayerLessonTargets) {
          tgt.innerHTML = lessonNameValue;
        }

        for (let tgt of this.audioPlayerSsmlTargets) {
          tgt.innerHTML = ssmlValue;
        }

        this.prepareAudio();
      })
      .catch((error) => {
        console.error(error); // Handle any errors that occurred during the fetch request
      });
  }

  prepareAudio() {
    var controller = this;
    var courseLessonId = this.audioPlayerTarget.getAttribute(
      "attr-course-lesson-id-value"
    );
    var audioUrl = this.audioPlayerTarget.getAttribute("attr-audio-url-value");
    var lessonNameValue = this.audioPlayerTarget.getAttribute(
      "attr-lesson-name-value"
    );
    var appNameValue = this.audioPlayerTarget.getAttribute(
      "attr-app-name-value"
    );
    var courseNameValue = this.audioPlayerTarget.getAttribute(
      "attr-course-name-value"
    );
    var artworkUrlValue = this.audioPlayerTarget.getAttribute(
      "attr-artwork-url-value"
    );
    var initialSeekValue = this.audioPlayerTarget.getAttribute(
      "attr-initial-seek-value"
    );

    this.idValue = courseLessonId;
    this.appValue = appNameValue;

    if (this.sound !== undefined) {
      this.sound.stop();
      controller.showPlay();
      this.sound.seek(initialSeekValue);
    }

    controller.completed = false;

    this.sound = new Howl({
      src: [audioUrl],
      html5: true,

      onpause: function () {
        // controller.sendPosition();
        controller.showPlay();
        clearInterval(controller.intervalId);
        controller.outsiderPlayPause(courseLessonId, "pause");
        controller.sendPosition();
      },
      onstop: function () {
        clearInterval(controller.intervalId);
        controller.outsiderPlayPause(courseLessonId, "pause");
      },
      onend: function () {
        controller.showPlay();
        clearInterval(controller.intervalId);
        controller.completed = true;
        controller.updatePercentage();
        controller.sendPosition();
        controller.closePlayer();
        controller.outsiderPlayPause(courseLessonId, "play_next_session");
      },
      onplay: function () {
        controller.showPause();
        controller.markPosition();
        // controller.sendPosition();
        controller.id = requestAnimationFrame(controller.step.bind(controller));
        controller.outsiderPlayPause(courseLessonId, "play");
      },
      onseek: debounce(function (event) {
        controller.id = requestAnimationFrame(controller.step.bind(controller));
        controller.seekValue = controller.sound.seek();
        initialSeekValue = controller.sound.seek();
      }, 1000),
    });

    function debounce(func, delay) {
      let timerId;
      return function (...args) {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    }

    this.sound.once("load", function () {
      if ("mediaSession" in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: lessonNameValue,
          artist: appNameValue,
          album: courseNameValue,
          artwork: [
            {
              src: artworkUrlValue,
              sizes: "500x500",
              type: "image/png",
            },
          ],
        });

        navigator.mediaSession.setActionHandler("play", function () {
          controller.play();
        });

        navigator.mediaSession.setActionHandler("pause", function () {
          controller.pause();
        });

        navigator.mediaSession.setActionHandler("seekbackward", function () {
          controller.rewind();
        });

        navigator.mediaSession.setActionHandler("seekforward", function () {
          controller.forward();
        });

        navigator.mediaSession.setActionHandler("seekto", function (event) {
          controller.sound.seek(event.seekTime);
        });
      }

      for (let tgt of controller.durationTargets) {
        tgt.innerHTML = controller.formatTime(controller.sound.duration());
      }

      if (initialSeekValue) {
        for (let tgt of controller.progressTargets) {
          tgt.value = initialSeekValue;
        }

        controller.sound.seek(initialSeekValue);
      }

      var totalDuration = controller.sound.duration();
      for (let tgt of controller.progressTargets) {
        tgt.setAttribute("max", totalDuration);
      }
    });

    if (this.volumeMuted) {
      this.sound.volume(0);
    }
    console.log(initialSeekValue);
    this.sound.seek(initialSeekValue);
    this.sound.play();
  }

  seek() {
    cancelAnimationFrame(this.id);
    for (let tgt of this.progressTargets) {
      var seekedTime = event.currentTarget.value;
      this.sound.seek(seekedTime);
    }
  }

  async fetchInitialSeekValue(subscriptionAudioSeekURL) {
    var controller = this;
    var initialSeekValue = this.seekValue !== undefined ? this.seekValue : 0;
    try {
      const response = await fetch(subscriptionAudioSeekURL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-CSRF-Token": this.getMetaValue("csrf-token"),
        },
      });

      const data = await response.json();

      if (data.code === 200) {
        initialSeekValue = data.seek;
        controller.seekValue = data.seek;
        this.seekValue = data.seek;
        this.course_lesson_id = data.course_lesson_id;
      }
    } catch (error) {
      // Handle any errors that occurred during the fetch request
      console.log(error);
      console.error("Error fetching initial seek value:", error);
    }
    return initialSeekValue;
  }

  markPosition() {
    if (this.idValue) {
      var controller = this;
      clearInterval(this.intervalId);
      let counter = 0;

      this.intervalId = window.setInterval(function () {
        counter++;
        controller.updatePercentage();
        if (counter % 5 === 0) {
          controller.sendPosition();
        }
      }, 1000);
    }
  }

  sendPosition() {
    // Calling from markPosition
    fetch(`/${this.appValue}/subscription_audios`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-Token": this.getMetaValue("csrf-token"),
      },
      body: JSON.stringify({
        seek: parseInt(this.sound.seek()),
        course_lesson_id: this.idValue,
        completed: this.completed,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.completed) {
          this.course_lesson_id = null;
          this.fetchAndUpdateCourseBtn();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updatePercentage() {
    var verticle_lines = document.querySelectorAll(
      `.lesson-bar-${this.idValue}`
    );
    let barColor;
    let appNameValue;
    verticle_lines.forEach((bar) => {
      appNameValue = bar.getAttribute("attr-app");
      if (appNameValue == "career") {
        barColor = "border-BrightBlue";
      } else if (appNameValue == "viva") {
        barColor = "border-BrightGreen";
      }
      bar.classList.remove("border-dashed", "border-BrightStone");
      bar.classList.add("border-solid", barColor);
    });

    var right_tick_bars = document.querySelectorAll(
      `.lesson-bar-right-tick-${this.idValue}`
    );

    for (let tgt of this.completedPercentageTargets) {
      if (this.idValue == tgt.getAttribute("attr-course-lesson-id")) {
        const appName = tgt.getAttribute("attr-app");
        let progressColor;
        if (appName == "career") {
          progressColor = "rgb(0, 88, 218)";
        } else if (appName == "viva") {
          progressColor = "rgb(74, 94, 26)";
        }

        var total_seconds = parseInt(
          tgt.getAttribute("attr-course-lesson-duration")
        );
        var completed_seconds = parseInt(this.sound.seek());
        var percent =
          total_seconds > 0 ? (completed_seconds / total_seconds) * 100 : 0;

        var prev_percent = parseInt(
          tgt.getAttribute("attr-lesson-completed-percent")
        );

        for (var step = prev_percent + 1; step <= percent; step++) {
          tgt.style.transition = "background 0.5s ease-in-out";
          var background = tgt.style.background;
          background = background.replace(
            `${progressColor} ${step - 1}%`,
            `${progressColor} ${step}%`
          );
          tgt.style.setProperty("background", background);
          // tgt.style.setProperty('background', `radial-gradient(closest-side, rgb(245,245,245) 80%, transparent 80% 100%), conic-gradient(rgb(74, 94, 26) ${step}%, rgb(215, 216, 205) 0)`);
        }

        if (this.completed) {
          right_tick_bars.forEach((bar) => {
            bar.classList.remove("hidden");
          });
        }
      }
    }
  }

  updateSliderCircleBg() {
    const width = window.innerWidth;
    try {
      this.completedPercentageTargets.forEach((target) => {
        var background = target.style.background;
        var currentValue = "white 75%";
        if (width <= 1023) {
          // Medium screen
          currentValue = "rgb(245, 245, 245) 75%";
          background = background.replace("white 75%", currentValue);
        } else {
          background = background.replace(
            "rgb(245, 245, 245) 75%",
            currentValue
          );
        }
        target.style.setProperty("background", background);
      });
    } catch (err) {
      console.log(err);
    }
  }

  maximizePlayer(event) {
    const parentWithAttr = event.target.closest("[attr-desc]");
    this.widthRemoveTarget.style.width = "0px";
    this.widthRemoveTarget.style.opacity = "0px";
    if (!parentWithAttr) {
      this.minPlayerContainerTarget.classList.add("hidden");
      this.maxPlayerContainerTarget.classList.remove("hidden");
    }
  }

  openTranscriptFromMin(event) {
    this.comingFrom = "min";
    this.showMaxTranscript(event);
  }

  toggleTranscriptFromMax(event) {
    // Check if maxTranscript is already visible or will be visible on this click
    if (this.maxTranscriptTarget.classList.contains("hidden")) {
      // Show transcript from maximised player
      this.comingFrom = "max";
      this.showMaxTranscript(event);
    } else {
      // Hide Transcript and back to previous state of player
      this.hideMaxTranscript(event);
      if (this.comingFrom == "min") {
        // Go back to minimized state
        this.minimizePlayer(event);
      }
    }
  }

  showMaxTranscript(event) {
    this.maxBasicDetailsTarget.classList.add("hidden");
    this.maxDetailsTarget.classList.add("hidden");
    this.maxTranscriptTarget.classList.remove("hidden");
  }

  hideMaxTranscript(event) {
    this.maxBasicDetailsTarget.classList.remove("hidden");
    this.maxDetailsTarget.classList.remove("hidden");
    this.maxTranscriptTarget.classList.add("hidden");
  }

  minimizePlayer(event) {
    this.minPlayerContainerTarget.classList.remove("hidden");
    this.maxPlayerContainerTarget.classList.add("hidden");
  }

  closePlayer(event) {
    this.idValue = undefined;
    this.isLeftSwiped = false;

    if (!this.maxPlayerContainerTarget.classList.contains("hidden")) {
      this.maxPlayerContainerTarget.classList.add("hidden");
    }

    if (!this.audioPlayerTarget.classList.contains("hidden")) {
      this.audioPlayerTarget.classList.add("hidden");
    }

    this.widthRemoveTarget.style.width = "0px";
    this.widthRemoveTarget.style.opacity = "0px";
  }

  updateVolume(event) {
    if (this.sound !== undefined) {
      this.sound.volume(event.currentTarget.value);
    }

    for (let tgt of this.audioPlayerVolumeTargets) {
      this.volumeData = event.currentTarget.value;
      tgt.value = this.volumeData;
    }

    if (parseFloat(event.currentTarget.value) == 0.0) {
      this.mute(event);
    } else {
      this.unmute(event);
    }
  }

  mute(event) {
    this.sound.mute(true);
    this.volumeMuted = true;

    for (let tgt of this.audioPlayerSoundTargets) {
      tgt.classList.add("hidden");
    }
    for (let tgt of this.audioPlayerMuteTargets) {
      tgt.classList.remove("hidden");
    }

    for (let tgt of this.audioPlayerVolumeTargets) {
      tgt.value = 0;
    }
  }

  unmute(event) {
    this.sound.mute(false);
    this.volumeMuted = false;

    if (this.volumeData == 0.0) {
      this.volumeData = 0.1;
      for (let tgt of this.audioPlayerVolumeTargets) {
        tgt.value = this.volumeData;
      }
      this.sound.volume(0.1);
    } else {
      this.sound.volume(this.volumeData);
    }

    for (let tgt of this.audioPlayerSoundTargets) {
      tgt.classList.remove("hidden");
    }
    for (let tgt of this.audioPlayerMuteTargets) {
      tgt.classList.add("hidden");
    }

    for (let tgt of this.audioPlayerVolumeTargets) {
      tgt.value = this.volumeData;
    }
  }

  outsiderPlayPause(courseLessonId, action) {
    const allLessons = document.querySelectorAll("[attr-group-id]");

    allLessons.forEach((ele) => {
      // Reset to default (Show play button on course_lesson card)
      status = ele.getAttribute("attr-status");
      if (status == "pause") {
        ele.classList.remove("hidden");
      } else if (status == "play") {
        ele.classList.add("hidden");
      }

      // Checking for making active and play/pause
      var groupId = ele.getAttribute("attr-group-id");
      if (groupId == courseLessonId) {
        if (status == "pause") {
          // Current element is pause button

          if (action == "play") {
            // Hide pause button
            ele.classList.add("hidden");
          } else {
            // Show pause button
            ele.classList.remove("hidden");
          }
        } else if (status == "play") {
          // Current element is play button
          if (action == "play") {
            // Show play button
            ele.classList.remove("hidden");
          } else {
            // Hide play button
            ele.classList.add("hidden");
          }
        }
      }
    });
  }

  // Outside player events (Related elements which are dependent on player states)

  pauseLesson() {
    try {
      event.stopPropagation();
    } catch (error) {}

    this.pauseTarget.click();
  }

  playNextOrResume() {
    const url = event.currentTarget.getAttribute(
      "attr-course-lesson-url-value"
    );
    this.courseStatusActionUrl = url;
    var currentBtn = event.currentTarget;
    const currentBtnText = event.currentTarget.getAttribute(
      "attr-course-action-btn-text"
    );

    fetch(url + "?course_lesson_id=" + this.course_lesson_id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-Token": this.getMetaValue("csrf-token"),
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (currentBtnText == "pause_session") {
          // this.pause();
          this.pauseTarget.click();
        } else if (data.current_course_state == "course_completed") {
          this.showCompletedCourseBtn();
        } else {
          this.courseLessonTargets.forEach((ele) => {
            if (
              ele.getAttribute("attr-course-lesson-id-value") ==
              data.course_lesson_id
            ) {
              ele.click();
              this.activeCourseLesson(data.course_lesson_id);
              return;
            }
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  fetchAndUpdateCourseBtn() {
    try {
      const current_lesson_id = this.idValue;
      var ele = document.getElementById("course_lesson_" + current_lesson_id);
      const url = ele.getAttribute("attr-fetch-status-url");
      if (url !== undefined) {
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-CSRF-Token": this.getMetaValue("csrf-token"),
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            if (data.current_course_state == "course_completed") {
              this.showCompletedCourseBtn();
            }

            if (this.completed) {
              this.completeCourseLesson(current_lesson_id);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } catch (err) {}
  }

  activeCourseLesson(course_lesson_id) {
    var ele = document.getElementById("course_lesson_" + course_lesson_id);
    var app = ele.getAttribute("attr-app");

    if (app == "viva") {
      ele.classList.remove("text-LightStone", "text-DarkStone");
      ele.classList.add("border-BrightGreen", "text-BrightGreen");
    } else if (app == "career") {
      ele.classList.remove("text-LightStone", "text-DarkStone");
      ele.classList.add("border-BrightBlue", "text-BrightBlue");
    }
  }

  completeCourseLesson(course_lesson_id) {
    var ele = document.getElementById("course_lesson_" + course_lesson_id);

    var app = ele.getAttribute("attr-app");

    if (app == "viva") {
      ele.classList.remove("group-hover:text-BrightGreen", "text-BrightGreen");
      ele.classList.add("bg-BrightGreen", "text-BrightStone");
    } else if (app == "career") {
      ele.classList.remove("group-hover:text-BrightBlue", "text-BrightBlue");
      ele.classList.add("bg-BrightBlue", "text-white");
    }
    ele.setAttribute("attr-play-status", "true");
  }

  showCompletedCourseBtn() {
    try {
      var actionForms = document.querySelectorAll(".course_action_form");
      actionForms.forEach((form) => {
        form.classList.add("hidden");
      });

      var completedBtns = document.querySelectorAll(".course_completed_btn");
      completedBtns.forEach((btn) => {
        btn.classList.remove("hidden");
      });
    } catch (err) {
      console.log(err);
    }
  }

  resetSwipeBox() {
    try {
      this.startX = 0;
      this.endX = 0;
      this.percentLeftSwiped = 0;
      this.isLeftSwiped = false;
      const swipeDivBox = document.getElementById("swipeDivBox");
      swipeDivBox.style.width = "0vw";
    } catch (err) {}
  }

  handleTouchStart(event) {
    this.startX = event.touches[0].clientX;
  }

  handleTouchEnd(event) {
    this.endX = event.changedTouches[0].clientX;
    var swipeDistance = this.endX - this.startX;
    var halfWidth = this.minPlayerContainerTarget.offsetWidth / 2;

    if (swipeDistance < -halfWidth || this.isLeftSwiped) {
      // Swipe left (full swipe)
      this.handleFullSwipe();
      this.isLeftSwiped = false;
    } else if (swipeDistance < 0) {
      // Swipe left (half swipe)
      this.handleHalfSwipe();
    }
  }

  handleTouchMove(event) {
    this.endX = event.changedTouches[0].clientX;
    var swipeDistance = this.endX - this.startX;

    var fullWidth = this.minPlayerContainerTarget.offsetWidth;
    var swipeDivBox = document.getElementById("swipeDivBox");

    var percentLeftSwiped = 0;
    if (swipeDistance < 0) {
      // Left swiping
      percentLeftSwiped = ((swipeDistance * -1) / fullWidth) * 100;

      if (this.isLeftSwiped) {
        percentLeftSwiped = 50 + percentLeftSwiped;
      }

      swipeDivBox.style.width = `${parseInt(percentLeftSwiped)}vw`;
    } else {
      // Right swiping
      if (this.isLeftSwiped) {
        this.isLeftSwiped = false;
        var percentRightSwiped = (swipeDistance / fullWidth) * 100;
        var remainingSwiped = 50 - percentRightSwiped;

        this.handleRightSwipe(remainingSwiped);
      }
    }

    this.percentLeftSwiped = percentLeftSwiped;

    swipeDivBox.style.opacity = "100";
  }

  handleHalfSwipe() {
    this.isLeftSwiped = true;
    const swipeDivBox = document.getElementById("swipeDivBox");
    const currentWidth = parseInt(this.percentLeftSwiped); // Starting width is 0
    const targetWidth = 50; // Final width is 100vw
    const animationDuration = 300; // Duration of the animation in milliseconds (adjust as needed)
    let startTime = null;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;

      if (progress >= animationDuration) {
        swipeDivBox.style.width = targetWidth + "vw";
        swipeDivBox.style.opacity = 100;
      } else {
        const percentage = progress / animationDuration;
        const newWidth =
          currentWidth + percentage * (targetWidth - currentWidth);
        swipeDivBox.style.width = newWidth + "vw";
        swipeDivBox.style.opacity = 100;
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }

  handleFullSwipe() {
    const swipeDivBox = document.getElementById("swipeDivBox");
    const currentWidth = parseInt(this.percentLeftSwiped); // Starting width is 0
    const targetWidth = 100; // Final width is 100vw
    const animationDuration = 300; // Duration of the animation in milliseconds (adjust as needed)
    let startTime = null;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;

      if (progress >= animationDuration) {
        swipeDivBox.style.width = targetWidth + "vw";
        swipeDivBox.style.opacity = 100;
        this.removePlayer();
      } else {
        const percentage = progress / animationDuration;
        const newWidth =
          currentWidth + percentage * (targetWidth - currentWidth);
        swipeDivBox.style.width = newWidth + "vw";
        swipeDivBox.style.opacity = 100;
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }

  handleRightSwipe(remainingSwiped) {
    const swipeDivBox = document.getElementById("swipeDivBox");
    const currentWidth = parseInt(remainingSwiped); // Starting width is 0
    const targetWidth = 0; // Final width is 100vw
    const animationDuration = 300; // Duration of the animation in milliseconds (adjust as needed)
    let startTime = null;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;

      if (progress >= animationDuration) {
        swipeDivBox.style.width = targetWidth + "vw";
        swipeDivBox.style.opacity = 100;
      } else {
        const percentage = progress / animationDuration;
        const newWidth =
          currentWidth - percentage * (targetWidth + currentWidth);
        swipeDivBox.style.width = newWidth + "vw";
        swipeDivBox.style.opacity = 100;
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }

  removePlayer() {
    try {
      this.pauseLesson();
      this.closePlayer();
    } catch (err) {
      console.log(err);
    }
  }
}
